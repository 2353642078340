import React from "react";

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://github.com/emmanuel-mwendwa"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i class="uil uil-github-alt"></i>
      </a>

      <a
        href="https://twitter.com/mwendwae054"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-twitter"></i>
      </a>

      <a
        href="https://wa.me/254742586034?text=Hi%20there!%20How%20may%20I%20help%20you%20today."
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i class="uil uil-whatsapp"></i>
      </a>
    </div>
  );
};

export default Social;
